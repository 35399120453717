import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ReactComponent as LeftIcon } from "../assets/chevron-left.svg";
import { ReactComponent as RightIcon } from "../assets/chevron-right.svg";
import { LEFT_PAGE, RIGHT_PAGE } from "../utils/constants";
import generatePageNumbers from "../utils/pagination/generatePageNumbers";
import findPageToJump from "../utils/pagination/findPageToJump";

class TablePagination extends PureComponent {
  static propTypes = {
    totalRecords: PropTypes.number,
    pageSize: PropTypes.number,
    pageNeighbours: PropTypes.oneOf([0, 1, 2]),
    onPageChange: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
    jumpOnMore: PropTypes.bool,
    simplePagination: PropTypes.bool,
    hasMoreData: PropTypes.bool
  };

  static defaultProps = {
    pageNeighbours: 1,
    pageSize: 10,
    jumpOnMore: false,
    simplePagination: false,
    hasMoreData: false
  };

  gotoPage = page => {
    const { onPageChange, totalRecords, pageSize, simplePagination } = this.props;
    const noOfPages = Math.ceil(totalRecords / pageSize);
    const currentPage = Math.max(0, Math.min(page, noOfPages));
    onPageChange(simplePagination ? page : currentPage);
  };

  renderPages = () => {
    const { currentPage, pageNeighbours, jumpOnMore, simplePagination } = this.props;

    if (simplePagination) {
      return [
        <li
          onClick={() => null}
          className={classNames("wb-pagination-item simple-item", {
            "wb-pagination-active": simplePagination
          })}
        >
          <span className="wb-pagination-link">{currentPage}</span>
        </li>
      ];
    }
    return generatePageNumbers(this.props).map((page, index) => {
      const handleJump = () => this.gotoPage(findPageToJump(page, currentPage, pageNeighbours));

      if (page === LEFT_PAGE || page === RIGHT_PAGE)
        return (
          <li
            key={index}
            onClick={jumpOnMore ? handleJump : undefined}
            className={classNames("wb-pagination-item", {
              "wb-pagination-no-jump": !jumpOnMore
            })}
          >
            <span>...</span>
          </li>
        );

      return (
        <li
          onClick={handleJump}
          key={index}
          className={classNames("wb-pagination-item", {
            "wb-pagination-active": currentPage === page
          })}
        >
          <span className="wb-pagination-link">{simplePagination ? currentPage : page}</span>
        </li>
      );
    });
  };

  handlePageNext = () => {
    const { totalRecords, pageSize, currentPage, hasMoreData, simplePagination } = this.props;
    const totalPages = Math.ceil(totalRecords / pageSize) || 0;
    const enableRight = !simplePagination ? currentPage < totalPages : hasMoreData;

    if (!enableRight) return null;

    this.gotoPage(currentPage + 1);
  };

  handlePagePrevious = () => {
    const enableLeft = this.props.currentPage > 1;

    if (!enableLeft) return null;

    this.gotoPage(this.props.currentPage - 1);
  };

  render() {
    const { totalRecords, pageSize, currentPage, hasMoreData, simplePagination } = this.props;
    const totalPages = Math.ceil(totalRecords / pageSize);

    if (!totalRecords && !simplePagination) return null;
    if (totalPages <= 0 && !simplePagination) return null;

    const enableLeft = currentPage > 1;
    const enableRight = !simplePagination ? currentPage < totalPages : hasMoreData;
    return (
      <div className="wb-pagination-container">
        <ul className="wb-pagination-list">
          <li
            key="left"
            onClick={this.handlePagePrevious.bind(this)}
            data-e2e-id="left"
            className={classNames("wb-pagination-item", "wb-pagination-nextPrevControl", {
              "wb-pagination-disabled": !enableLeft
            })}
          >
            <LeftIcon className="wb-pagination-icon" />
          </li>
          {this.renderPages()}
          <li
            key="right"
            data-e2e-id="right"
            onClick={this.handlePageNext.bind(this)}
            className={classNames("wb-pagination-item", "wb-pagination-nextPrevControl", {
              "wb-pagination-disabled": !enableRight
            })}
          >
            <RightIcon className="wb-pagination-icon" />
          </li>
        </ul>
      </div>
    );
  }
}

export default TablePagination;
