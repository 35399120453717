import _ from "lodash";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  combineParentAndChild,
  checkExpand,
  sortData,
  modifyComponentCell,
  checkExpandTableSelectAll,
  expandTableIsSelected,
  expandTableToggleSelection,
  expandTableToggleAllSelection,
  checkExpandTableIndeterminateSelect,
  checkExpandTableParentIndeterminateSelect,
  expandTableShouldHideSelectCheck
} from "../utils/expandTable";
import Table from "./Table";
import ExpandCell from "./ExpandCell";
import TableCheckbox from "./TableCheckBox";

class ExpandTable extends PureComponent {
  static propTypes = {
    keyField: PropTypes.string,
    className: PropTypes.string,
    striped: PropTypes.bool,
    childKeyField: PropTypes.string,
    columns: PropTypes.array,
    style: PropTypes.object,
    selectable: PropTypes.bool,
    showChildSelect: PropTypes.bool,
    selection: PropTypes.object,
    shouldHideSelect: PropTypes.func.isRequired,
    childIdentificationKey: PropTypes.string,
    data: PropTypes.array,
    expandChildComponent: PropTypes.func,
    manual: PropTypes.bool,
    showColumnSeparator: PropTypes.bool
  };

  static defaultProps = {
    keyField: "id",
    className: "",
    striped: false,
    childKeyField: "id",
    columns: [],
    shouldHideSelect: () => false,
    selection: { parentSelection: [], childSelection: [] },
    selectable: false,
    showChildSelect: true,
    childIdentificationKey: "",
    data: [],
    style: {},
    expandChildComponent: () => <div />,
    manual: true,
    showColumnSeparator: false
  };

  state = {
    expandedList: [],
    sort: []
  };

  handleExpandClick(event, value) {
    event.stopPropagation();
    const { expandedList } = this.state;
    const newList = _.xor(expandedList, [value]);
    this.setState({ expandedList: newList });
  }

  handleSort = sortInputs => {
    this.setState({ expandedList: [], sort: sortInputs });
  };

  render() {
    const {
      columns,
      childIdentificationKey,
      data,
      striped,
      className,
      style,
      expandChildComponent,
      keyField,
      selection,
      onSelect,
      showChildSelect,
      manual,
      sorted,
      onSortedChange,
      childKeyField,
      selectable,
      shouldHideSelect,
      showColumnSeparator
    } = this.props;
    const { expandedList, sort } = this.state;
    const expandCellTemplate = {
      Header: "",
      sortable: false,
      resizable: false,
      hideIfOnlyColumn: true,
      className: classNames(
        "wb-cell-expand-icon",
        { "wb-cell": !showColumnSeparator },
        { "wb-cell-border": showColumnSeparator }
      ),
      headerClassName: classNames(
        "wb-cell-expand-icon",
        { "wb-header": !showColumnSeparator },
        { "wb-header-border": showColumnSeparator }
      ),
      Cell: ({ row }) => (
        <ExpandCell
          childIdentificationKey={childIdentificationKey}
          expandChildComponent={expandChildComponent}
          handleExpandClick={e => this.handleExpandClick(e, row._original.id)}
          row={row._original}
          expandedList={expandedList}
        />
      )
    };
    const tableStyle = { maxHeight: `${window.screen.height - 200}px`, ...style };
    const tableClass = classNames(className, { "-striped": striped });
    const modifyColumnDefinition = modifyComponentCell(columns);
    const sortedArray = sortData([...data], sort, modifyColumnDefinition, childIdentificationKey);
    const modifiedData = combineParentAndChild(
      sortedArray,
      childIdentificationKey,
      expandedList,
      keyField
    );

    const tableProps = {
      getTrProps: (state, rowInfo) => {
        const { original } = rowInfo;
        const isExpanded = checkExpand(original, expandedList);
        const display = isExpanded ? "flex" : "none";
        return { style: { display } };
      },
      freezeWhenExpanded: true,
      defaultPageSize: modifiedData.length,
      ...this.props,
      columns: [expandCellTemplate, ...modifyColumnDefinition],
      data: modifiedData,
      style: tableStyle,
      className: tableClass,
      sorted: manual ? sorted : this.state.sort,
      onSortedChange: manual ? onSortedChange : this.handleSort,
      sortedData: modifiedData.map(data => ({ _original: data, ...data })),
      expandedList: this.state.expandedList
    };

    const commonArgs = {
      data,
      selection,
      keyField,
      childKeyField,
      childIdentificationKey,
      onSelect
    };
    const expandableIndeterminate = row =>
      checkExpandTableParentIndeterminateSelect({ ...commonArgs, row });
    const selectAll = checkExpandTableSelectAll({
      ...commonArgs
    });
    const indeterminate = checkExpandTableIndeterminateSelect({
      ...commonArgs
    });
    const isSelected = key =>
      expandTableIsSelected({
        ...commonArgs,
        key
      });
    const checkboxComponent = props => (
      <TableCheckbox
        {...props}
        shouldHideSelect={({ row }) =>
          expandTableShouldHideSelectCheck(row, showChildSelect, shouldHideSelect)
        }
      />
    );
    const toggleSelection = key =>
      expandTableToggleSelection({
        ...commonArgs,
        key
      });
    const toggleAll = _ => expandTableToggleAllSelection(commonArgs);

    const selectTableProps = {
      expandableIndeterminate,
      indeterminate,
      selectAll,
      isSelected,
      checkboxComponent,
      toggleSelection,
      showChildSelect,
      toggleAll
    };

    const props = selectable ? { ...tableProps, ...selectTableProps } : tableProps;

    return (
      <Table
        {...props}
        selection={selection.parentSelection}
        showColumnSeparator={showColumnSeparator}
      />
    );
  }
}

export default ExpandTable;
